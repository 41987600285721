const connectButton = document.getElementById(
  "connectButton"
) as HTMLButtonElement;
const programButton = document.getElementById("programButton") as HTMLButtonElement;
const terminal = document.getElementById("terminal");
const table = document.getElementById("fileTable") as HTMLTableElement;
const programButtonProgress = document.getElementById("programButtonProgress") as HTMLElement;
const chooseFileButton = document.getElementById("chooseFileButton") as HTMLButtonElement;
let selectedFirmwareFile: { data: string; name: string } | null = null;

// This is a frontend example of Esptool-JS using local bundle file
// To optimize use a CDN hosted version like
// https://unpkg.com/esptool-js@0.2.0/bundle.js
import { ESPLoader, FlashOptions, LoaderOptions, Transport } from "./lib";
import { serial } from "web-serial-polyfill";
if (!navigator.serial && navigator.usb) navigator.serial = serial;

declare let Terminal; // Terminal is imported in HTML script
declare let CryptoJS; // CryptoJS is imported in HTML script

const term = new Terminal({
    cols: 80,
    rows: 30,
    scrollOnInput: true,
    cursorBlink: true,
    fontSize: 14,
    fontFamily: 'ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, monospace',
    theme: {
        background: '#ffffff',
        foreground: '#000000',
        cursor: '#000000'
    }
});
term.open(terminal);

term.onData(() => term.scrollToBottom());

let device = null;
let transport: Transport;
let chip: string = null;
let esploader: ESPLoader;
let flag_connected = false;
let program_progress = 0;

if (program_progress == 0 && programButtonProgress) {
  programButtonProgress.textContent = "Start";
}


/**
 * The built in Event object.
 * @external Event
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/Event}
 */

/**
 * File reader handler to read given local file.
 * @param {Event} evt File Select event
 */
function handleFileSelect(evt) {
  const file = evt.target.files[0];

  if (!file) return;

  const reader = new FileReader();

  reader.onload = (ev: ProgressEvent<FileReader>) => {
    evt.target.data = ev.target.result;
  };

  reader.readAsBinaryString(file);
}

const espLoaderTerminal = {
  clean() {
    term.clear();
  },
  writeLine(data) {
    console.log("Terminal write:", data);
    term.writeln(data);
    term.scrollToBottom();
  },
  write(data) {
    console.log("Terminal write:", data);
    term.write(data);
    term.scrollToBottom();
  },
};

connectButton.onclick = async () => {
  if (!flag_connected) {
    // Connection logic
    if (device === null) {
      device = await navigator.serial.requestPort({});
      transport = new Transport(device, true);
    }

    try {
      const flashOptions = {
        transport,
        baudrate: 921600,
        terminal: espLoaderTerminal,
        debugLogging: false,
      } as LoaderOptions;
      esploader = new ESPLoader(flashOptions);
      chip = await esploader.main();
    } catch (e) {
      console.error(e);
      term.writeln(`Error: ${e.message}`);
      return; // Exit if connection fails
    }

    console.log("Settings done for: " + chip);
    connectButton.textContent = "Disconnect: " + chip;
    flag_connected = true;
    connectButton.classList.add("danger");
    programButton.disabled = false;
  } else {
    // Disconnect logic
    if (transport) await transport.disconnect();
    term.reset();
    connectButton.textContent = "Connect";
    connectButton.style.display = "initial";
    cleanUp();
    flag_connected = false;
    connectButton.classList.remove("danger");
    programButton.disabled = true;
  }
};


/**
 * The built in HTMLTableRowElement object.
 * @external HTMLTableRowElement
 * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/HTMLTableRowElement}
 */

/**
 * Remove file row from HTML Table
 * @param {HTMLTableRowElement} row Table row element to remove
 */
function removeRow(row: HTMLTableRowElement) {
  const rowIndex = Array.from(table.rows).indexOf(row);
  table.deleteRow(rowIndex);
}

/**
 * Clean devices variables on chip disconnect. Remove stale references if any.
 */
function cleanUp() {
  device = null;
  transport = null;
  chip = null;
}




/**
 * Validate the provided files images and offset to see if they're valid.
 * @returns {string} Program input validation result
 */
function validateProgramInputs() {
  const offsetArr = [];
  const rowCount = table.rows.length;
  let row;
  let offset = 0;
  let fileData = null;

  // check for mandatory fields
  for (let index = 1; index < rowCount; index++) {
    row = table.rows[index];

    //offset fields checks
    const offSetObj = row.cells[0].childNodes[0];
    offset = parseInt(offSetObj.value);

    // Non-numeric or blank offset
    if (Number.isNaN(offset))
      return "Offset field in row " + index + " is not a valid address!";
    // Repeated offset used
    else if (offsetArr.includes(offset))
      return "Offset field in row " + index + " is already in use!";
    else offsetArr.push(offset);

    const fileObj = row.cells[1].childNodes[0];
    fileData = fileObj.data;
    if (fileData == null) return "No file selected for row " + index + "!";
  }
  return "success";
}

// Define firmware URLs configuration
const firmwareConfig = {
  "esp32c3": {
    "xpb_v1.1.4": "http://127.0.0.1:5500/XPBFlasher/src/firmware/esp32c3_xpb_v1.1.4.bin"
  },
  "esp32c6": {
    "xpb_v1.1.4": "https://github.com/nicho810/XIAO-PowerBread/releases/download/v1.1.4/esp32c6_xpb_v1.1.4.bin"
  },
  "esp32s3": {
    "xpb_v1.1.4": "https://github.com/nicho810/XIAO-PowerBread/releases/download/v1.1.4/esp32s3_xpb_v1.1.4.bin"
  }
};

chooseFileButton.onclick = () => {
  const input = document.createElement('input');
  input.type = 'file';
  input.accept = '.bin';  // Only accept .bin files
  
  input.onchange = (event: Event) => {
    const file = (event.target as HTMLInputElement).files?.[0];
    if (!file) return;

    const reader = new FileReader();
    reader.onload = (e: ProgressEvent<FileReader>) => {
      if (e.target?.result) {
        // Store the file data and name
        selectedFirmwareFile = {
          data: e.target.result as string,
          name: file.name
        };
        espLoaderTerminal.writeLine(`Selected firmware file: ${file.name}`);
        chooseFileButton.textContent = `Selected: ${file.name}`;
      }
    };
    reader.readAsBinaryString(file);
  };

  input.click();
};

programButton.onclick = async () => {
  programButton.disabled = true;
  
  try {
    if (!esploader || !flag_connected) {
      throw new Error('Device not connected. Please connect first.');
    }

    let firmwareString: string;
    
    // Check if we have a manually selected file
    if (selectedFirmwareFile) {
      firmwareString = selectedFirmwareFile.data;
      espLoaderTerminal.writeLine(`Using selected file: ${selectedFirmwareFile.name}`);
    } else {
      // Existing URL-based firmware loading logic
      const targetChip = (document.getElementById('targetChipSelect') as HTMLSelectElement).value;
      const firmwareVersion = (document.getElementById('firmwareVersionSelect') as HTMLSelectElement).value;
      
      const firmwareUrl = firmwareConfig[targetChip]?.[firmwareVersion];
      if (!firmwareUrl) {
        throw new Error(`No firmware URL found for ${targetChip} version ${firmwareVersion}`);
      }

      espLoaderTerminal.writeLine(`Loading firmware from: ${firmwareUrl}`);
      const response = await fetch(firmwareUrl);
      
      if (!response.ok) {
        throw new Error(`Failed to load firmware file: ${firmwareUrl} (HTTP ${response.status})`);
      }

      const firmwareData = await response.arrayBuffer();
      const decoder = new TextDecoder('latin1');
      firmwareString = decoder.decode(new Uint8Array(firmwareData));
    }

    // Rest of the existing programming logic
    const fileArray = [{
      data: firmwareString,
      address: 0x0000
    }];

    const flashOptions: FlashOptions = {
      fileArray: fileArray,
      flashSize: "keep",
      eraseAll: false,
      compress: true,
      flashMode: "dio",
      flashFreq: "40m",
      reportProgress: (fileIndex, written, total) => {
        const progress = Math.floor((written / total) * 100);
        const progressSpan = document.getElementById('programButtonProgress');
        if (progressSpan) {
          progressSpan.textContent = `${progress}%`;
        }
        // Add terminal progress updates at meaningful intervals
        if (progress % 10 === 0) {
          espLoaderTerminal.writeLine(`Programming progress: ${progress}%`);
        }
        if (progress === 100) {
          espLoaderTerminal.writeLine('\x1b[32mVerifying... please wait....\x1b[0m');
        }
      },
      calculateMD5Hash: (image) =>
        CryptoJS.MD5(CryptoJS.enc.Latin1.parse(image)),
    } as FlashOptions;
    
    espLoaderTerminal.writeLine('\x1b[32mStarting flash process...\x1b[0m');
    await esploader.writeFlash(flashOptions);
    espLoaderTerminal.writeLine('\x1b[32mProgramming completed successfully!\x1b[0m');
    
    
  } catch (error) {
    console.error(error);
    espLoaderTerminal.writeLine(`Error: ${error.message}`);
  } finally {
    // Re-enable button after programming (success or failure)
    programButton.disabled = false;
    // Reset progress display
    const progressSpan = document.getElementById('programButtonProgress');
    if (progressSpan) {
      progressSpan.textContent = 'Done!';
    }

    espLoaderTerminal.writeLine('\x1b[32mPlease reset the device manually.\x1b[0m');
  }
};

